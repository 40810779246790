<template>
    <es-table class="employ-analytics" :headers="headers" :items="subdivisions" @update="getSubdivisionsPages">
        <div class="subdivisions__actions" v-if="hasRight('subdivisions.manage')">
            <v-btn class="evi-button-green evi-button-green--fill" @click="createSubdivisionModal = true">
                Создать подразделение
            </v-btn>
        </div>
        <v-dialog
                v-model="createSubdivisionModal"
                max-width="612px"
        >
            <CreateSubdivision @close="createSubdivisionModal = false" @updateUsers="updateUsers"/>
        </v-dialog>
    </es-table>
</template>

<script>
import {mapActions, mapState} from "vuex";
import CreateSubdivision from "../../components/subdivisions/CreateSubdivision";
import {hasRight} from '@/utils/access/hasRight';

export default {
    name: "Subdivisions",
    components: {CreateSubdivision},
    data: () => ({
        headers: [
            {
                text: 'Название',
                value: 'name',
            },
            {
                text: 'Руководитель',
                value: 'head',
            },
            {
                text: 'Сотрудники',
                value: 'users',
            },
        ],
        createSubdivisionModal: false,
    }),
    computed: {
        ...mapState("users", ["subdivisions",]),
    },
    methods: {
        ...mapActions({
            getSubdivisionsPages: 'users/getSubdivisionsPages',
        }),
        hasRight: hasRight,
        updateUsers () {
            this.getSubdivisionsPages({
                per_page: 10,
                page: 1,
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.subdivisions {
    &__actions {
        margin-left: 23px;
    }
}
</style>
