<template>
    <v-card class="modal-create">
        <v-form ref="form" v-model="valid">
            <v-card-title class="d-flex justify-center">
                <span class="text-h5 text-center mb-11">Создание подразделения</span>
            </v-card-title>
            <v-card-text class="modal-create__form">
                <div class="close-dialog" @click="closeDialog">
                    <img :src="require('@/assets/img/common/close-dialog.svg')" />
                </div>
                <v-col
                        cols="12"
                >
                    <v-text-field
                            class="evi-text-field"
                            color="#44D370"
                            v-model="subdivisionName"
                            placeholder="Введите название отдела"
                            :rules="subdivisionRules"
                    >
                        <template v-slot:label>
                            Название
                            <span class="important">*</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col
                        cols="12"
                >
                    <v-autocomplete
                            class="evi-autocomplete"
                            color="#44D370"
                            v-model="headmaster"
                            :items="users"
                            item-text="name"
                            item-value="id"
                            placeholder="Выберите руководителя"
                            :rules="headmasterRules"
                            :loading="userLoading"
                            @focus="loadUsers"
                            :search-input.sync="userSearch"
                            @input.native="searchUser(userSearch)"
                            clearable
                            item-color="green"
                    >
                        <template v-slot:label>
                            Руководитель
                            <span class="important">*</span>
                        </template>
                        <template v-slot:append-item>
                            <div v-intersect="userIntersect" />
                        </template>
                      <template v-slot:item="{item}">
                        <v-avatar
                            color="#FFFFFF"
                            size="30"
                            class="user-page__avatar mr-3"
                        >
                          <img
                              :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                              alt=""
                          >
                        </v-avatar>
                        <div class="v-list-item__title">{{item.name}}</div>
                      </template>
                        <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-card-text>
            <v-card-actions class="modal-create__actions">
                <v-btn class="evi-button-green evi-button-green--fill" @click="create">
                    Создать подразделение
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import {mapActions} from "vuex";
    import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";

export default {
    name: "CreateSubdivision",
    mixins: [UserAutocompleteRequestsMixin],
    data: () => ({
        valid: true,
        headmaster: null,
        subdivisionName: '',
        headmasterRules: [(v) => !!v || 'Выберите руководителя'],
        subdivisionRules: [(v) => !!v || 'Введите названия подразделения'],
    }),
    methods: {
        ...mapActions({
            createSubdivision: 'users/createSubdivision',
        }),
        validate () {
            return !!this.$refs.form.validate();
        },
        create () {
            if (this.validate()) {
                let payload = {
                    name: this.subdivisionName,
                    head_user_id: this.headmaster,
                };

                this.createSubdivision(payload).then(() => {
                    this.closeDialog();
                    this.$emit('updateUsers');
                });
            }
        },
        closeDialog () {
            this.$emit('close');
            this.subdivisionName = '';
            this.headmaster = null;
            this.$refs.form.resetValidation();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
